import React from "react";
import styled, { css } from "styled-components";
import { ParallaxBanner, ParallaxProvider } from "react-scroll-parallax";
import { breakpoints, colors } from "../styles/variables";

const ParallaxBannerImg = styled(ParallaxBanner)`
    position: relative !important;
    height: 220px !important;
    top: 3% !important;
    z-index: 1;

    @media (min-width: ${breakpoints.sm}) {
        height: 300px !important;
    }

    @media (min-width: ${breakpoints.md}) {
        height: 400px !important;
    }

    @media (min-width: ${breakpoints.lg}) {
        height: 500px !important;
    }
`;

const BannerImage = (imgsrc) => {
    const GetValue = (data) => {
        for (let key in data) {
            if (data.hasOwnProperty(key)) {
                return data[key];
            }
        }
    };

    return (
        <ParallaxProvider>
            <ParallaxBannerImg
                layers={[
                    {
                        image: GetValue(imgsrc),
                        amount: 0.4,

                        expanded: true,
                    },
                ]}
            ></ParallaxBannerImg>
        </ParallaxProvider>
    );
};
export default BannerImage;
